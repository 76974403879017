<template>
  <div class="form-content">
    <el-form ref="form" :model="form">
      <el-form-item :label="$t('Time Period Name')" style="max-width: 80%">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Start Time')" style="max-width: 80%">
        <el-time-select v-model="form.startTime" :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:30'
        }" value-format="HH:mm" placeholder="選擇時間">
        </el-time-select>
      </el-form-item>
      <el-form-item :label="$t('End Time')" style="max-width: 10%">
        <el-time-select v-model="form.endTime" :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:30'
        }" value-format="HH:mm" placeholder="選擇時間">
        </el-time-select>
      </el-form-item>
      <el-form-item :label="$t('Discount (%)')" style="max-width: 10%">
        <el-input type="number" v-model="form.discount"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Related Meal')" style="max-width: 70%">
        <el-select v-model="form.relatedMeal" multiple>
          <el-option v-for="meal in meals" :key="meal.id" :label="meal.name" :value="meal.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="submit-wrapper">
      <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';

export default {
  name: 'TimePeriodForm',
  data() {
    const form = {
      name: '',
      startTime: '',
      endTime: '',
      discount: 0,
      relatedMeal: [],
    };
    return {
      form,
      meals: [],
    }
  },
  mounted(){
    this.loadMeals();
  },
  methods: {
    async loadMeals(){
      try{
        const loginInfo = Common.getLoginInfo();
        const meals = await Menu.loadAllMeals(this.apiUrl, loginInfo);
        this.meals = meals;
      }catch(err){
        console.log(err);
      }
    },
    submit(){
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentTime': async function () {
      const form = {
        name: this.currentTime.name,
        startTime: this.currentTime.start_time,
        endTime: this.currentTime.end_time,
        discount: this.currentTime.discount,
        relatedMeal: JSON.parse(this.currentTime.related_meal),
      };
      this.form = form;
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
    currentTime: state => state.marketing.currentTime,
  }),
}
</script>

<style lang="scss">
.form-content {
  padding-left: 20px;

  .el-form-item__label {
    float: none;
  }

  .update-button {
    .el-form-item__content {
      text-align: right;
    }
  }

  .submit-wrapper {
    text-align: right;
  }
}
</style>