var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-form-item',{staticStyle:{"max-width":"80%"},attrs:{"label":_vm.$t('Time Period Name')}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{staticStyle:{"max-width":"80%"},attrs:{"label":_vm.$t('Start Time')}},[_c('el-time-select',{attrs:{"picker-options":{
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },"value-format":"HH:mm","placeholder":"選擇時間"},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('el-form-item',{staticStyle:{"max-width":"10%"},attrs:{"label":_vm.$t('End Time')}},[_c('el-time-select',{attrs:{"picker-options":{
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },"value-format":"HH:mm","placeholder":"選擇時間"},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1),_c('el-form-item',{staticStyle:{"max-width":"10%"},attrs:{"label":_vm.$t('Discount (%)')}},[_c('el-input',{attrs:{"type":"number"},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", $$v)},expression:"form.discount"}})],1),_c('el-form-item',{staticStyle:{"max-width":"70%"},attrs:{"label":_vm.$t('Related Meal')}},[_c('el-select',{attrs:{"multiple":""},model:{value:(_vm.form.relatedMeal),callback:function ($$v) {_vm.$set(_vm.form, "relatedMeal", $$v)},expression:"form.relatedMeal"}},_vm._l((_vm.meals),function(meal){return _c('el-option',{key:meal.id,attrs:{"label":meal.name,"value":meal.id}})}),1)],1)],1),_c('div',{staticClass:"submit-wrapper"},[_c('el-button',{staticClass:"submit-button",attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('Submit')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }